import React, {Component} from 'react';
import {Button, FileUploader, Popup} from "devextreme-react";
import LDH from '../helpers/LeopardDataHelper';
import $ from 'jquery';
import LRH from "../helpers/LeopardReactHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import Form, {Item, GroupItem, SimpleItem, Label} from 'devextreme-react/form';
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import ScrollView from "devextreme-react/scroll-view";
import SparkMD5 from "spark-md5";

class LeopardReportSchedulerPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gridViewData: null,
            popupVisible: false,
            popupTitle: null,
            contentHeight: "",
            popupWidth: "1024px",
            popupHeight: "768px",
            popupPosition: {
                my: 'center',
                at: 'center',
                of: window
            },
            formData: []
        };

        this.uiObjectInstances = [];
        this.instanceInitialized = false;
        this.disposingAllInstances = false;
        this.gridDefinition = null;
        this.rowData = null;
        this.reportPayload = null;
    };

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
    };

    popupOnHide = (e) => {
        this.showOrHideSpinningWheel(false);
        this.setState({formData: []});
    };

    popupOnShowing = () => {
        let title = this.props.popupTitle;
        let contentHeight = this.props.contentHeight;
        this.uiObjectInstances = [];

        if (!LDH.IsObjectNull(this.props.popupHeight) &&
            !LDH.IsObjectNull(this.props.popupWidth)) {
            this.setState({
                popupTitle: title,
                contentHeight: contentHeight,
                popupHeight: this.props.popupHeight,
                popupWidth: this.props.popupWidth,
                isPrefillLocked: false
            });
        } else {
            this.setState({
                popupTitle: title,
                contentHeight: contentHeight
            });
        }
    };

    showOrHideSpinningWheel = (show) => {
        if (show) {
            $(".leopard-report-scheduler .leopard-spinner").show();
            $(".leopard-report-scheduler").show();
            $(".leopard-gridview-popup-container").hide();
        } else {
            $(".leopard-report-scheduler .leopard-spinner").hide();
            $(".leopard-report-scheduler").hide();
            $(".leopard-gridview-popup-container").show();
        }
    };

    popupOnShown = () => {
        let that = this;
        this.gridDefinition = this.props.gridDefinition;
        this.rowData = window.Global_PopupCustomColumnData.rowData;

        let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
        let directory = "report";
        let fileName = this.rowData.name;

        let documentRetrieveType = "leopardsystems.report.retrieve";
        that.showOrHideSpinningWheel(true);

        LeopardAjaxHelper.RetrieveDocumentFromS3(loginUserId, fileName, directory, function (documentData) {
            try {
                let jsonData = (documentData.indexOf("<Code>NoSuchKey</Code>") > -1 || documentData.indexOf("<!DOCTYPE html>") > -1)
                    ? null : JSON.parse(documentData);
                that.reportPayload = jsonData;

                let r = that.rowData;
                let schedulerId = r.documentid + "_" + r.owner + "_" + r.type + "_" + r.version;
                let schedulerIdHash = SparkMD5.hash(schedulerId, false);
                let serviceRequestType = "leopardsystems.report.schedule.list";
                let dataToPost = {namePrefix: schedulerIdHash + "_"};

                LeopardAjaxHelper.SendEventToReportService(dataToPost, function (response) {
                    let schedules = response.body.results.Schedules;
                    if (!LDH.IsObjectNull(schedules) && schedules.length > 0) {
                        that.populateSchedulerToFormData(that, schedules);
                    }
                    that.showOrHideSpinningWheel(false);
                }, function (error, sessionTimeout) {
                    if (sessionTimeout !== undefined && sessionTimeout === true) {
                        LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                    } else {
                        LRH.ShowToast("Failed to send data to the backend.", "error", 5000);
                    }
                }, serviceRequestType);
            } catch (ex) {
                LRH.ShowToast("Unable to view schedules. This file does not exist on the server.", "error", 5000);
            }
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to retrieve file.", "error", 5000);
            }
            that.showOrHideSpinningWheel(false);
        }, documentRetrieveType);
    };

    addUIObjectInstance = (data) => {
        this.uiObjectInstances[data.name] = data.instance;
    };

    inputFieldValueOnChanged = (data, thisComp, reportDef) => {
        let that = thisComp;
        let clonedFormData = LDH.DeepClone(that.state.formData);
        if (!data.isReportArgs) {
            clonedFormData[data.index][data.fieldName] = data.fieldValue;
        } else {
            for (let i = 0; i < clonedFormData.length; i++) {
                if (clonedFormData[i].id !== data.scheduleId) continue;

                for (let j = 0; j < clonedFormData[i].reportArgs.length; j++) {
                    if (clonedFormData[i].reportArgs[j].Name !== data.fieldName) {
                        continue;
                    }
                    if (LDH.IsObjectNull(data.valueType) || data.valueType === "value1") {
                        clonedFormData[i].reportArgs[j].Value = data.fieldValue;
                    } else if (data.valueType === "value2") {
                        clonedFormData[i].reportArgs[j].Value2 = data.fieldValue;
                    }
                }

                let organizationId = LDH.GetOrganizationIdFromUserProfile(window.userProfile);
                let customDataSources = [];
                let args = {variables: []};

                for (let j = 0; j < clonedFormData[i].reportArgs.length; j++) {
                    let value1 = clonedFormData[i].reportArgs[j].Value;
                    if (!LDH.IsValueEmpty(clonedFormData[i].reportArgs[j].Value2)) {
                        let value2 = clonedFormData[i].reportArgs[j].Value2;
                        value1 = LDH.ReplaceAll(value1, "(NNN)", "(" + value2 + ")");
                    }
                    args.variables[clonedFormData[i].reportArgs[j].Name] = value1;
                }
                let keys = Object.keys(args.variables);

                if (!LDH.IsObjectNull(reportDef.reportCustomDataSources)) {
                    customDataSources = LDH.GetScheduledCustomDataSources(reportDef, args, keys, organizationId);
                }

                let parameters = LDH.IsValueEmpty(reportDef.oDataParametersForScheduledReport) ?
                    "" : reportDef.oDataParametersForScheduledReport;

                let parentQuery1 = reportDef.oDataParametersParent1ForScheduledReport;
                let parentQuery2 = reportDef.oDataParametersParent2ForScheduledReport;
                let parentQuery3 = reportDef.oDataParametersParent3ForScheduledReport;

                parameters = LDH.GetSanitizedParameters(args, keys, parameters, true);
                parameters = LDH.ReplaceAll(parameters, "{Input_UserGroupId}", organizationId);
                parameters = LDH.FilterMacro(parameters);

                if (!LDH.IsObjectNull(parentQuery1) && !LDH.IsValueEmpty(parentQuery1)) {
                    parentQuery1 = LDH.GetSanitizedParameters(args, keys, parentQuery1, false);
                    parentQuery1 = LDH.ReplaceAll(parentQuery1, "{Input_UserGroupId}", organizationId);
                    parentQuery1 = LDH.FilterMacro(parentQuery1);
                }

                if (!LDH.IsObjectNull(parentQuery2) && !LDH.IsValueEmpty(parentQuery2)) {
                    parentQuery2 = LDH.GetSanitizedParameters(args, keys, parentQuery2, false);
                    parentQuery2 = LDH.ReplaceAll(parentQuery2, "{Input_UserGroupId}", organizationId);
                    parentQuery2 = LDH.FilterMacro(parentQuery2);
                }

                if (!LDH.IsObjectNull(parentQuery3) && !LDH.IsValueEmpty(parentQuery3)) {
                    parentQuery3 = LDH.GetSanitizedParameters(args, keys, parentQuery3, false);
                    parentQuery3 = LDH.ReplaceAll(parentQuery3, "{Input_UserGroupId}", organizationId);
                    parentQuery3 = LDH.FilterMacro(parentQuery3);
                }

                clonedFormData[i].defaultDataSource = {
                    parentQuery1: parentQuery1,
                    parentQuery2: parentQuery2,
                    parentQuery3: parentQuery3,
                    finalQuery: parameters,
                    dataSourceName: "defaultDataSource"
                };
                clonedFormData[i].customDataSources = customDataSources;
            }
        }
        that.setState({formData: clonedFormData});
    };

    populateSchedulerToFormData = (thisComp, schedules) => {
        let that = thisComp;
        let clonedData = LDH.DeepClone(that.state.formData);
        let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);

        for (let s = 0; s < schedules.length; s++) {
            let schedulePL = JSON.parse(schedules[s].payload);
            schedules[s].payload = schedulePL;
        }

        let schedulesSorted = schedules.sort(function (a, b) {
            return a.payload.reportPayload.order - b.payload.reportPayload.order;
        });

        for (let s = 0; s < schedulesSorted.length; s++) {
            let schedulePayload = schedulesSorted[s].payload;
            let reportPayload = schedulePayload.reportPayload;
            let documentMetadata = reportPayload.documentMetadata;

            clonedData.push({
                documentMetadata: documentMetadata,
                recipients: reportPayload.recipients,
                cronExpression: reportPayload.cronExpression,
                timezone: reportPayload.timezone,
                fileFormat: reportPayload.fileFormat,
                id: reportPayload.id,
                md5: reportPayload.md5,
                reportArgs: reportPayload.reportArgs,
                order: reportPayload.order,
                isScheduledReport: true,
                loginUserId: loginUserId
            });
        }

        that.setState({formData: clonedData});
    };

    addButtonOnClick = (thisComp, documentMetadata) => {
        let reportLayout = thisComp.reportPayload.reportConfig.reportLayout;
        thisComp.addSchedulerToFormData(thisComp, reportLayout, documentMetadata);
    };

    onSelectedFilesChanged = (thisComp, e) => {
        let that = thisComp;
        let reader = new FileReader();
        reader.onload = function (event) {
            let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);
            let fileData = JSON.parse(event.target.result);

            for (let i = 0; i < fileData.length; i++) {
                let r = that.rowData;
                fileData[i].documentMetadata.documentid = r.documentid;
                fileData[i].documentMetadata.owner = r.owner;
                fileData[i].documentMetadata.type = r.type;
                fileData[i].documentMetadata.version = r.version;
                fileData[i].documentMetadata.id = r.id;

                let schedulerId = r.documentid + "_" + r.owner + "_" + r.type + "_" + r.version;
                fileData[i].md5 = SparkMD5.hash(schedulerId, false);
                fileData[i].id = LDH.GenerateGuid().substring(0, 8);
                fileData[i].loginUserId = loginUserId;
            }
            that.setState({formData: fileData});
        };
        if (e.value !== null && e.value.length > 0) {
            reader.readAsText(e.value[0]);
        }
    };

    importButtonOnClick = (thisComp) => {
        let that = thisComp;
        let $container = $(".schedulerPopupfileUploader_" + that.props.dataViewId);
        that.uiObjectInstances["fileUploader"].instance.reset();
        $(".dx-fileuploader-button", $container).trigger("click");
    };

    exportButtonOnClick = (thisComp) => {
        let that = thisComp;
        let content = that.state.formData;
        LRH.DownloadStringToFile("SchedulerSettings.json", JSON.stringify(content));
    };

    addSchedulerToFormData = (thisComp, reportLayout, documentMetadata) => {
        let that = thisComp;
        let clonedData = LDH.DeepClone(that.state.formData);
        let r = documentMetadata
        let schedulerId = r.documentid + "_" + r.owner + "_" + r.type + "_" + r.version;
        let schedulerIdHash = SparkMD5.hash(schedulerId, false);
        let variableKeys = Object.keys(reportLayout.Dictionary.Variables);

        let reportArgs = [];
        for (let i = 0; i < variableKeys.length; i++) {
            reportLayout.Dictionary.Variables[i].Value = "";
            reportLayout.Dictionary.Variables[i].Value2 = "";
            reportArgs.push(reportLayout.Dictionary.Variables[i]);
        }
        let loginUserId = LDH.GetUserIdFromUserProfile(window.userProfile);

        clonedData.push({
            documentMetadata: documentMetadata,
            recipients: "",
            cronExpression: "",
            timezone: "Australia/Sydney",
            fileFormat: "PDF",
            id: LDH.GenerateGuid().substring(0, 8),
            md5: schedulerIdHash,
            reportArgs: reportArgs,
            order: clonedData.length,
            isScheduledReport: true,
            loginUserId: loginUserId
        });
        that.setState({formData: clonedData});
    };

    removeSchedulerFromFormData = (thisComp, index) => {
        let that = thisComp;
        let clonedData = LDH.DeepClone(that.state.formData);
        clonedData.splice(index, 1);

        if (LDH.IsObjectNull(clonedData)) clonedData = [];
        that.setState({formData: clonedData}, function () {
            that.showOrHideSpinningWheel(false);
        });
    };

    cancelButtonOnClick = () => {
        window.Global_PopupTempObjectInstance.option("visible", false);
    };

    saveButtonOnClick = () => {
        let that = this;
        let r = that.rowData;
        let clonedData = LDH.DeepClone(that.state.formData);
        for (let i = 0; i < clonedData.length; i++) {
            clonedData[i].order = i;
        }

        let schedulerId = r.documentid + "_" + r.owner + "_" + r.type + "_" + r.version;
        let schedulerIdHash = SparkMD5.hash(schedulerId, false);
        let dataToPost = {schedules: clonedData, namePrefix: schedulerIdHash + "_"};
        let serviceRequestType = "leopardsystems.report.schedule.create";

        that.showOrHideSpinningWheel(true);
        LeopardAjaxHelper.SendEventToReportService(dataToPost, function (response) {
            window.Global_PopupTempObjectInstance.option("visible", false);
            LRH.ShowToast("Your schedules have been successfully saved.", "success", 5000);
        }, function (error, sessionTimeout) {
            if (sessionTimeout !== undefined && sessionTimeout === true) {
                LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
            } else {
                LRH.ShowToast("Failed to send data to the backend.", "error", 5000);
            }
            window.Global_PopupTempObjectInstance.option("visible", false);
            that.showOrHideSpinningWheel(false);
        }, serviceRequestType);
    };

    renderReportArgs = (thisComp, reportArgs, scheduleId) => {
        let that = thisComp;
        let reportDef = that.reportPayload.reportConfig;

        return reportArgs.map(function (item, i) {
            if (item.Name === "Input_TimezoneOffset") return null;
            if (item.Name === "Input_UserGroupId") return null;
            if (item.Name === "Input_EmailTo") return null;

            if (item.Name === "Input_TimezoneName") {
                return (
                    <SimpleItem key={scheduleId + "_" + item.Name} editorType={"dxSelectBox"}
                                editorOptions={{
                                    displayExpr: "name", valueExpr: "id", searchEnabled: false,
                                    value: item.Value,
                                    onValueChanged: (e) => that.inputFieldValueOnChanged({
                                        e,
                                        fieldName: item.Name,
                                        fieldValue: e.value,
                                        index: i,
                                        scheduleId: scheduleId,
                                        isReportArgs: true
                                    }, that, reportDef), showDropDownButton: true,
                                    dataSource: LeopardDropdownHelper.DropdownSelectionTimeZone
                                }}>
                        <Label text={item.Alias.replace(":", "")}/>
                    </SimpleItem>
                );
            }
            if (item.Name === "Input_DateFrom" || item.Name === "Input_DateTo") {
                return (
                    <GroupItem key={scheduleId + "_" + item.Name} colCount={4}>
                        <SimpleItem colSpan={3} editorType={"dxSelectBox"} cssClass={"leopard-formitem-no-padding"}
                                    editorOptions={{
                                        displayExpr: "name", valueExpr: "id", searchEnabled: false,
                                        value: item.Value,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e,
                                            fieldName: item.Name,
                                            fieldValue: e.value,
                                            index: i,
                                            scheduleId: scheduleId,
                                            isReportArgs: true
                                        }, that, reportDef), showDropDownButton: true,
                                        dataSource: LeopardDropdownHelper.DropdownSelectionPredefinedDateRange
                                    }}>
                            <Label text={item.Alias.replace(":", "")}/>
                        </SimpleItem>
                        <SimpleItem editorType={"dxNumberBox"}
                                    cssClass={"leopard-formitem-no-padding leopard-formitem-empty-label"}
                                    editorOptions={{
                                        value: item.Value2, showSpinButtons: true,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e,
                                            fieldName: item.Name,
                                            fieldValue: e.value,
                                            index: i,
                                            scheduleId: scheduleId,
                                            isReportArgs: true,
                                            valueType: "value2"
                                        }, that, reportDef)
                                    }}>
                            <Label text={" "}/>
                        </SimpleItem>
                    </GroupItem>
                );
            } else {
                return (
                    <SimpleItem key={scheduleId + "_" + item.Name} editorType={"dxTextBox"}
                                editorOptions={{
                                    value: item.Value,
                                    onValueChanged: (e) => that.inputFieldValueOnChanged({
                                        e,
                                        fieldName: item.Name,
                                        fieldValue: e.value,
                                        index: i,
                                        scheduleId: scheduleId,
                                        isReportArgs: true
                                    }, that, reportDef)
                                }}>
                        <Label text={item.Alias.replace(":", "")}/>
                    </SimpleItem>
                );
            }
        });
    };

    renderSchedulerForm = (thisComp) => {
        let that = thisComp;
        if (that.reportPayload === null) return null;
        let reportDef = that.reportPayload.reportConfig;

        return that.state.formData.map(function (item, i) {
            return (
                <React.Fragment key={"schedulerForm_Control_" + i}>
                    <div>
                        <div className={"leopard-border-left-stripe"} style={{display: "flex"}}>
                            <div className={"leopard-sidebar-stripe-container"}>
                                <div className={"leopard-sidebar-stripe-content"}>
                                    <div className={"leopard-sidebar-stripe-number"}>
                                        {i + 1}
                                    </div>
                                </div>
                            </div>
                            <Form colCount={1} formData={item} labelLocation={"top"}
                                  className={"leopard-generic-form-container"}
                                  ref={(e) => that.addUIObjectInstance({name: "form", instance: e})}
                                  validationGroup={"formeditor-validation-group"}
                                  style={{paddingRight: "10px", paddingLeft: "15px"}}>
                                <GroupItem caption="Report filter" colCount={3}>
                                    {that.renderReportArgs(that, item.reportArgs, item.id)}
                                </GroupItem>
                                <GroupItem caption="Scheduler configuration" colCount={3}>
                                    <Item editorType={"dxSelectBox"} editorOptions={{
                                        value: item.fileFormat, displayExpr: "text", valueExpr: "value",
                                        searchEnabled: false,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e,
                                            fieldName: "fileFormat",
                                            fieldValue: e.value,
                                            index: i,
                                            scheduleId: item.id,
                                            isReportArgs: false
                                        }, that, reportDef), showDropDownButton: true,
                                        dataSource: LeopardDropdownHelper.DropdownSelectionScheduledReportFileFormat
                                    }}>
                                        <Label text={"File format"}/>
                                    </Item>
                                    <Item editorType={"dxTextBox"} editorOptions={{
                                        value: item.cronExpression,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e,
                                            fieldName: "cronExpression",
                                            fieldValue: e.value,
                                            index: i,
                                            scheduleId: item.id,
                                            isReportArgs: false
                                        }, that, reportDef)
                                    }}>
                                        <Label text={"Cron expression"}/>
                                    </Item>
                                    <Item editorType={"dxSelectBox"} editorOptions={{
                                        value: item.timezone, displayExpr: "name", valueExpr: "id",
                                        searchEnabled: false,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e,
                                            fieldName: "timezone",
                                            fieldValue: e.value,
                                            index: i,
                                            scheduleId: item.id,
                                            isReportArgs: false
                                        }, that, reportDef), showDropDownButton: true,
                                        dataSource: LeopardDropdownHelper.DropdownSelectionTimeZone
                                    }}>
                                        <Label text={"Time zone"}/>
                                    </Item>
                                    <Item editorType={"dxTextBox"} editorOptions={{
                                        value: item.recipients,
                                        onValueChanged: (e) => that.inputFieldValueOnChanged({
                                            e,
                                            fieldName: "recipients",
                                            fieldValue: e.value,
                                            index: i,
                                            scheduleId: item.id,
                                            isReportArgs: false
                                        }, that, reportDef)
                                    }} colSpan={3}>
                                        <Label text={"Recipients"}/>
                                    </Item>
                                </GroupItem>
                            </Form>
                        </div>
                    </div>
                    <div style={{marginTop: "10px", display: "flex"}}>
                        <div style={{width: "100%"}}></div>
                        <div>
                            <a className={"leopard-generic-hyperlink"} href={"#_"} style={{marginRight: "10px"}}
                               onClick={(e) => that.removeSchedulerFromFormData(that, i)}>
                                Remove
                            </a>
                        </div>
                    </div>
                </React.Fragment>

            );
        });
    };

    render() {
        if (this.disposingAllInstances) return null;

        return (
            <Popup className={'leopard-popup-window report-scheduler'}
                   dragEnabled={true} hideOnOutsideClick={false} onHiding={this.popupOnHide}
                   onShowing={this.popupOnShowing} showTitle={true} title={this.state.popupTitle}
                   ref={this.props.popupReportSchedulerInstance} onShown={this.popupOnShown}
                   fullScreen={false} width={this.state.popupWidth} height={this.state.popupHeight}
                   resizeEnabled={false} minHeight={"480px"} minWidth={"640px"}
                   position={this.state.popupPosition} onResizeEnd={(e) => this.onResizeEnd(e)}>
                <div className={"leopard-report-scheduler leopard-loading-icon"}
                     style={{display: "flex", margin: "0", width: "100%", height: "100%"}}>
                    <div style={{margin: "auto"}}>
                        <i className="leopard-spinner fas fa-spinner fa-pulse" style={{
                            fontSize: "25px",
                            display: this.state.formData.length === 0 ? "block" : "none"
                        }}></i>
                    </div>
                </div>
                <div className={"leopard-gridview-popup-container"}
                     style={{display: this.state.formData.length === 0 ? "none" : "block"}}>
                    <div className={"leopard-gridview-popup-content"}>
                        <div className={"leopard-popup-window-editorpanel"}>
                            <ScrollView showScrollbar={"onHover"} scrollByThumb={true} scrollByContent={false}
                                        ref={(e) => this.addUIObjectInstance({name: "scrollview", instance: e})}>
                                <div
                                    className={"leopard-popup-window-reportscheduler-container leopard-generic-popup-form-layout"}>
                                    {this.renderSchedulerForm(this)}
                                </div>
                            </ScrollView>
                        </div>
                    </div>
                    <div className={"leopard-gridview-popup-buttons"}>
                        <div style={{display: "flex"}}>
                            <Button className="leopard-button" text={'Import'}
                                    style={{marginRight: "15px", marginLeft: "15px"}}
                                    ref={(e) => this.addUIObjectInstance({name: "buttonImport", instance: e})}
                                    onClick={(e) => this.importButtonOnClick(this)}>
                            </Button>
                            <Button className="leopard-button" text={'Export'} style={{marginRight: "15px"}}
                                    ref={(e) => this.addUIObjectInstance({name: "buttonExport", instance: e})}
                                    onClick={(e) => this.exportButtonOnClick(this)}>
                            </Button>
                            <div style={{width: "100%"}}></div>
                            <Button className="leopard-button" text={'Add'} style={{marginRight: "15px"}}
                                    ref={(e) => this.addUIObjectInstance({name: "buttonAdd", instance: e})}
                                    onClick={(e) => this.addButtonOnClick(this, this.rowData)}>
                            </Button>
                            <Button className="leopard-button" text={'Cancel'} style={{marginRight: "15px"}}
                                    ref={(e) => this.addUIObjectInstance({name: "buttonCancel", instance: e})}
                                    onClick={(e) => this.cancelButtonOnClick(e)}>
                            </Button>
                            <Button className="leopard-button" text={'Apply'}
                                    ref={(e) => this.addUIObjectInstance({name: "buttonSave", instance: e})}
                                    onClick={(e) => this.saveButtonOnClick(e)}
                                    style={{marginRight: "15px"}}>
                            </Button>
                            <div style={{display: "none"}}>
                                <FileUploader multiple={false} uploadMode={"useButtons"} accept={'.json'}
                                              className={"schedulerPopupfileUploader_" + this.props.dataViewId}
                                              ref={(e) => this.addUIObjectInstance({name: "fileUploader", instance: e})}
                                              onValueChanged={(e) => this.onSelectedFilesChanged(this, e)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        );
    };
}

export default LeopardReportSchedulerPopup;
