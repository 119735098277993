import React, {Component} from 'react';
import {Button, Popup, TileView} from 'devextreme-react';
import TabPanel from 'devextreme-react/tab-panel';
import $ from "jquery";
import LRH from "../helpers/LeopardReactHelper";
import LeopardAjaxHelper from "../helpers/LeopardAjaxHelper";
import LDH from "../helpers/LeopardDataHelper";
import LeopardDropdownHelper from "../helpers/LeopardDropdownHelper";
import LeopardStaticUIConfig from "../foundation/LeopardStaticUIConfig";
import LeopardQueryHelper from "../helpers/LeopardQueryHelper";
import FileUploader from 'devextreme-react/file-uploader';
import imageCompression from 'browser-image-compression';

class LeopardAttachmentPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tileViewItems: [],
            popupVisible: false,
            numPages: null
        };
        this.uiObjectInstances = [];
        this.instanceInitialized = false;
        this.disposingAllInstances = false;
        this.showThumbnails = true;
        this.emptyImageUrl = "/css/images/EmptyPhoto.jpg";
        this.selectedPhotoId = null;
    }

    componentWillUnmount = () => {
        this.disposingAllInstances = true;
        LRH.DisposeUIInstancesFromList(this.uiObjectInstances);
        window["leopard-popup-photowidget-data-" + this.props.dataViewId] = null;
    };

    tileViewItemImageOnClick = (data, thisComp) => {
        $(".leopard-tileviewitem-image").removeClass("selected");
        $("." + data.id).addClass("selected");

        $(".leopard-photo-dragdrop-zone").removeClass("selected");
        $("#leopard-photo-dragdrop-zone-" + data.id).addClass("selected");
        this.selectedPhotoId = data.id;

        let commandLinkData = window.Global_PopupCustomColumnData.data;
        let allowUploadPhotos = !LDH.IsObjectNull(commandLinkData.allowUploadPhotos)
            && commandLinkData.allowUploadPhotos;

        let showDelete = false;
        for (let i = 0; i < thisComp.state.tileViewItems.length; i++) {
            let imageUrl = thisComp.state.tileViewItems[i].imageUrl;
            if (thisComp.selectedPhotoId === thisComp.state.tileViewItems[i].id &&
                allowUploadPhotos && imageUrl.indexOf("EmptyPhoto.jpg") === -1) {
                showDelete = true;
            }
        }
        let dataViewId = thisComp.props.dataViewId;

        if (showDelete) {
            $(".attachmentPopup_photoGalleryButtonDelete_" + dataViewId).css("display", "inline");
        } else {
            $(".attachmentPopup_photoGalleryButtonDelete_" + dataViewId).hide();
        }
        setTimeout(function () {
            $(".leopard-photogallery-enlarged-image").attr("src", data.originalImageUrl);
        }, 10);
    };

    photoGalleryImageOnError = () => {
        let commandLinkData = window.Global_PopupCustomColumnData.data;
        if (!LDH.IsObjectNull(commandLinkData.allowUploadPhotos) && commandLinkData.allowUploadPhotos) {
            return;
        }
        LRH.ShowToast("Your request has expired or photo could not be found, " +
            "please close this window and try again.", "error", 5000);
    };

    photoGalleryFullScreenOnClick = () => {
        window.open($(".leopard-photogallery-enlarged-image").attr("src"));
    };

    updatePhotoCountOnButton = (thisComp) => {
        let commandLinkData = window.Global_PopupCustomColumnData.data;
        let allowUploadPhotos = !LDH.IsObjectNull(commandLinkData.allowUploadPhotos)
            && commandLinkData.allowUploadPhotos;

        let totalCount = 0;
        for (let i = 0; i < thisComp.state.tileViewItems.length; i++) {
            let imageUrl = thisComp.state.tileViewItems[i].imageUrl;
            if (allowUploadPhotos && imageUrl.indexOf("EmptyPhoto.jpg") === -1) {
                totalCount++;
            }
        }
        $("#FormEditor_TopBar_PhotosIndicator_" + thisComp.props.dataViewId + " " +
            ".leopard-topmenu-indicator-number-text").text(totalCount);

        if (totalCount === 0) {
            $("#FormEditor_TopBar_PhotosIndicator_" + thisComp.props.dataViewId).hide();
        } else {
            $("#FormEditor_TopBar_PhotosIndicator_" + thisComp.props.dataViewId).css("display", "flex");
        }
    }

    photoGallerySaveOnClick = (e) => {
        let data = this.state.tileViewItems;
        window["leopard-popup-photowidget-data-" + this.props.dataViewId] = data;
        this.props.popupButtonSaveOnClick(e);
    }

    reportDownloadOnClick = () => {
        let linkSource = this.pdfFile;
        let blob = new Blob([linkSource], {type: 'application/pdf'});
        let src = URL.createObjectURL(blob);
        let downloadLink = document.createElement("a");
        let fileName = "Report.pdf";
        downloadLink.href = src;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    addUIObjectInstance = (data) => {
        this.uiObjectInstances[data.name] = data.instance;
    };

    popupOnHide = () => {
        this.updatePhotoCountOnButton(this);
        this.setState({popupVisible: false, tileViewItems: []});
        $(".leopard-photogallery-enlarged-image").removeAttr("src");
        $(".leopard-photocallery-container-nophoto").hide();

        let focusedInput = window["formwidget-focused-input-" + this.props.dataViewId];
        if (!LDH.IsObjectNull(focusedInput)) {
            setTimeout(function(){
                $("#" + focusedInput + " input.dx-texteditor-input").focus();
            }, 500);
        }
    };

    popupOnShowing = () => {
        $(".leopard-popupwindow-loading-progress").show();
        if (!LDH.IsObjectNull(window.Global_PopupCustomColumnData.data.showThumbnails) &&
            !LDH.IsValueEmpty(window.Global_PopupCustomColumnData.data.showThumbnails)) {
            this.showThumbnails = window.Global_PopupCustomColumnData.data.showThumbnails;
        }
    };

    popupOnShown = () => {
        let that = this;
        let commandLinkData = window.Global_PopupCustomColumnData.data;
        let parentRowData = window.Global_PopupCustomColumnData.e.row.data;
        let sourceId = window.Global_PopupCustomColumnData.data.dataSourceId;
        let itemId = parentRowData[sourceId];

        let columnType = window.Global_PopupCustomColumnData.data.columnType;
        let reportDataViewId = window.Global_PopupCustomColumnData.data.reportDataViewId;

        let urlOverride = "";
        if (!LDH.IsObjectNull(window.Global_PopupCustomColumnData.data.requestURLOverride) &&
            !LDH.IsValueEmpty(window.Global_PopupCustomColumnData.data.requestURLOverride)) {
            urlOverride = window.Global_PopupCustomColumnData.data.requestURLOverride;
            urlOverride = LDH.ConvertArrayMacroToString(urlOverride, parentRowData, null);
            urlOverride = LDH.FilterMacro(urlOverride);
        }

        let postDataProcessing = "";
        if (!LDH.IsObjectNull(window.Global_PopupCustomColumnData.data.requestURLOverrideDataProcessing) &&
            !LDH.IsValueEmpty(window.Global_PopupCustomColumnData.data.requestURLOverrideDataProcessing)) {
            postDataProcessing = window.Global_PopupCustomColumnData.data.requestURLOverrideDataProcessing;
        }

        if (columnType === "photo-gallery") {
            $(".tabpanel_header_0").closest(".dx-item.dx-tab").show();
            $(".tabpanel_header_1").closest(".dx-item.dx-tab").hide();
            that.uiObjectInstances["tabPanel"].instance.option("selectedIndex", 0);

            let allowUploadPhotos = !LDH.IsObjectNull(commandLinkData.allowUploadPhotos) && commandLinkData.allowUploadPhotos;
            if (allowUploadPhotos) {
                let items = [];
                for (let lp = 0; lp < 4; lp++) {
                    items.push({
                        imageUrl: that.emptyImageUrl,
                        id: LDH.GenerateGuid(),
                        itemId,
                        originalImageUrl: that.emptyImageUrl
                    });
                }

                let cacheData = window["leopard-popup-photowidget-data-" + this.props.dataViewId];
                if (!LDH.IsObjectNull(cacheData)) items = cacheData;

                that.setState({tileViewItems: items}, function () {
                    setTimeout(function () {
                        let firstItem = that.state.tileViewItems[0];
                        let firstOriginalUrl = firstItem.originalImageUrl;

                        $(".leopard-photogallery-enlarged-image").attr("src", firstOriginalUrl);
                        $(".leopard-photogallery-downloadimage-url").attr("url", firstOriginalUrl);
                        $(".leopard-photogallery-downloadimage-url").attr("itemid", itemId);
                        $(".leopard-popupwindow-loading-progress").hide();
                        $(".leopard-photocallery-container-nophoto").hide();
                    }, 100);
                });
            } else {
                LeopardAjaxHelper.GetAttachmentsByItemId(itemId, true, function (result) {
                    let items = LDH.GetImageListFromResult(result, itemId);

                    if (items.length === 0) {
                        $(".leopard-popupwindow-loading-progress").hide();
                        $(".leopard-photocallery-container-nophoto").show();
                    } else {
                        that.setState({tileViewItems: items}, function () {
                            setTimeout(function () {
                                let firstItem = that.state.tileViewItems[0];
                                let firstOriginalUrl = firstItem.originalImageUrl;

                                $(".leopard-photogallery-enlarged-image").attr("src", firstOriginalUrl);
                                $(".leopard-photogallery-downloadimage-url").attr("url", firstOriginalUrl);
                                $(".leopard-photogallery-downloadimage-url").attr("itemid", itemId);
                                $(".leopard-popupwindow-loading-progress").hide();
                                $(".leopard-photocallery-container-nophoto").hide();
                            }, 100);
                        });
                    }
                }, function () {
                    LRH.ShowToast("Failed to retrieve photos from the server.", "error", 5000);
                    $(".leopard-popupwindow-loading-progress").hide();
                    $(".leopard-photocallery-container-nophoto").show();
                }, urlOverride, postDataProcessing, true);
            }
        } else if (columnType === "pdf-viewer") {
            $(".tabpanel_header_0").closest(".dx-item.dx-tab").hide();
            $(".tabpanel_header_1").closest(".dx-item.dx-tab").show();
            that.uiObjectInstances["tabPanel"].instance.option("selectedIndex", 1);

            let userProfile = window.userProfile;
            let userId = LDH.GetUserIdFromUserProfile(userProfile);
            let organizationId = LDH.GetOrganizationIdFromUserProfile(userProfile);

            LeopardAjaxHelper.GetDataViewById(userId, reportDataViewId, function (result) {
                let version = LDH.ReplaceAll(LeopardStaticUIConfig.ControlCentreVersion, ".", "");
                if ($("#stimulsoft-viewer-css").length === 0) {
                    $("head").prepend('<link rel="stylesheet" id="stimulsoft-viewer-css" type="text/css" ' +
                        'href="/css/stimulsoft/2022.2.6/stimulsoft.viewer.office2013.whiteorange.css?v=' + version + '" />');
                }
                if ($("#stimulsoft-designer-css").length === 0) {
                    $("head").prepend('<link rel="stylesheet" id="stimulsoft-designer-css" type="text/css" ' +
                        'href="/css/stimulsoft/2022.2.6/stimulsoft.designer.office2013.whiteorange.css?v=' + version + '" />');
                }
                if ($("#stimulsoft-reports").length === 0) {
                    $("head").append('<script id="stimulsoft-reports" async ' +
                        'src="/js/stimulsoft/2022.2.6/stimulsoft.reports.js?v=' + version + '"></script>');
                }
                if ($("#stimulsoft-viewer").length === 0) {
                    $("head").append('<script id="stimulsoft-viewer" async ' +
                        'src="/js/stimulsoft/2022.2.6/stimulsoft.viewer.js?v=' + version + '"></script>');
                }
                if ($("#stimulsoft-designer").length === 0) {
                    $("head").append('<script id="stimulsoft-designer" async ' +
                        'src="/js/stimulsoft/2022.2.6/stimulsoft.designer.js?v=' + version + '"></script>');
                }
                window.Stimulsoft.Base.StiLicense.key = LeopardStaticUIConfig.ReportLicenseKey;

                let culture = window.Stimulsoft.System.Globalization.CultureInfo.cultures["en-AU"];
                window.Stimulsoft.System.Globalization.CultureInfo.currentCulture = culture;

                let reportDef = result.dataViewNote;
                let parameters = reportDef.oDataParametersForNormalReport;
                let parentQuery1 = reportDef.oDataParametersParent1ForNormalReport;
                let parentQuery2 = reportDef.oDataParametersParent2ForNormalReport;
                let parentQuery3 = reportDef.oDataParametersParent3ForNormalReport;

                let keys = Object.keys(parentRowData);
                let timezoneName = "Australia/Sydney";

                parameters = LDH.SetDateParametersForCommandLink(parentRowData, keys, parameters, timezoneName);
                parameters = LDH.ReplaceAll(parameters, "{Input_UserGroupId}", organizationId);
                parameters = LDH.FilterMacro(parameters);

                for (let i2 = 0; i2 < keys.length; i2++) {
                    parameters = LDH.ReplaceAll(parameters, "{" + keys[i2] + "}", parentRowData[keys[i2]]);
                }

                if (!LDH.IsObjectNull(parentQuery1) && !LDH.IsValueEmpty(parentQuery1)) {
                    parentQuery1 = LDH.SetDateParametersForCommandLink(parentRowData, keys, parentQuery1, timezoneName);
                    parentQuery1 = LDH.ReplaceAll(parentQuery1, "{Input_UserGroupId}", organizationId);
                    parentQuery1 = LDH.FilterMacro(parentQuery1);
                    for (let i2 = 0; i2 < keys.length; i2++) {
                        parentQuery1 = LDH.ReplaceAll(parentQuery1, "{" + keys[i2] + "}", parentRowData[keys[i2]]);
                    }
                }

                if (!LDH.IsObjectNull(parentQuery2) && !LDH.IsValueEmpty(parentQuery2)) {
                    parentQuery2 = LDH.SetDateParametersForCommandLink(parentRowData, keys, parentQuery2, timezoneName);
                    parentQuery2 = LDH.ReplaceAll(parentQuery2, "{Input_UserGroupId}", organizationId);
                    parentQuery2 = LDH.FilterMacro(parentQuery2);
                    for (let i2 = 0; i2 < keys.length; i2++) {
                        parentQuery2 = LDH.ReplaceAll(parentQuery2, "{" + keys[i2] + "}", parentRowData[keys[i2]]);
                    }
                }

                if (!LDH.IsObjectNull(parentQuery3) && !LDH.IsValueEmpty(parentQuery3)) {
                    parentQuery3 = LDH.SetDateParametersForCommandLink(parentRowData, keys, parentQuery3, timezoneName);
                    parentQuery3 = LDH.ReplaceAll(parentQuery3, "{Input_UserGroupId}", organizationId);
                    parentQuery3 = LDH.FilterMacro(parentQuery3);
                    for (let i2 = 0; i2 < keys.length; i2++) {
                        parentQuery3 = LDH.ReplaceAll(parentQuery3, "{" + keys[i2] + "}", parentRowData[keys[i2]]);
                    }
                }

                if (!LDH.IsObjectNull(reportDef.scriptShapingForReport) &&
                    !LDH.IsValueEmpty(reportDef.scriptShapingForReport)) {
                    try {
                        eval(reportDef.scriptShapingForReport);
                    } catch (ex) {
                        console.log("Failed to execute scriptShapingForReport.");
                    }
                }

                let apiUrl = LDH.GetODataAPIGatewayUrl("");
                if (!LDH.IsObjectNull(reportDef.dataSourceVersion) &&
                    reportDef.dataSourceVersion === "v2") {
                    apiUrl = ""
                }

                let postDataQuery = reportDef.clientSideQueryForReport;
                return LeopardQueryHelper.ExecuteQueriesForReport(apiUrl, parentQuery1, parentQuery2, parentQuery3, parameters, postDataQuery, [], function (data) {
                    let resultData = {};
                    let ajaxRequestObj = [];

                    if (!LDH.IsObjectNull(data) && typeof data.finalData !== "undefined") {
                        data = data.finalData;
                    }

                    resultData["_defaultDS"] = data;
                    let ajaxCount = 0;
                    let ajaxAdditionalCount = 0;

                    if (!LDH.IsObjectNull(reportDef.reportCustomDataSources)) {
                        for (let v = 0; v < reportDef.reportCustomDataSources.length; v++) {
                            let customQuery = reportDef.reportCustomDataSources[v].query;
                            let dsName = reportDef.reportCustomDataSources[v].name;
                            let postDataQuery = reportDef.reportCustomDataSources[v].postDataQuery;
                            let customParentQuery1 = reportDef.reportCustomDataSources[v].parentQuery1;
                            let customParentQuery2 = reportDef.reportCustomDataSources[v].parentQuery2;
                            let customParentQuery3 = reportDef.reportCustomDataSources[v].parentQuery3;

                            customQuery = LDH.ReplaceAll(customQuery, "{Input_UserGroupId}", organizationId);
                            customQuery = LDH.FilterMacro(customQuery);
                            for (let i2 = 0; i2 < keys.length; i2++) {
                                customQuery = LDH.ReplaceAll(customQuery, "{" + keys[i2] + "}", parentRowData[keys[i2]]);
                            }

                            if (!LDH.IsObjectNull(customParentQuery1) && !LDH.IsValueEmpty(customParentQuery1)) {
                                customParentQuery1 = LDH.SetDateParametersForCommandLink(parentRowData, keys, customParentQuery1, timezoneName);
                                customParentQuery1 = LDH.ReplaceAll(customParentQuery1, "{Input_UserGroupId}", organizationId);
                                customParentQuery1 = LDH.FilterMacro(customParentQuery1);
                                for (let i2 = 0; i2 < keys.length; i2++) {
                                    customParentQuery1 = LDH.ReplaceAll(customParentQuery1, "{" + keys[i2] + "}", parentRowData[keys[i2]]);
                                }
                                if (!LDH.IsValueEmpty(customParentQuery1)) ajaxAdditionalCount++;
                            }

                            if (!LDH.IsObjectNull(customParentQuery2) && !LDH.IsValueEmpty(customParentQuery2)) {
                                customParentQuery2 = LDH.SetDateParametersForCommandLink(parentRowData, keys, customParentQuery2, timezoneName);
                                customParentQuery2 = LDH.ReplaceAll(customParentQuery2, "{Input_UserGroupId}", organizationId);
                                customParentQuery2 = LDH.FilterMacro(customParentQuery2);
                                for (let i2 = 0; i2 < keys.length; i2++) {
                                    customParentQuery2 = LDH.ReplaceAll(customParentQuery2, "{" + keys[i2] + "}", parentRowData[keys[i2]]);
                                }
                                if (!LDH.IsValueEmpty(customParentQuery2)) ajaxAdditionalCount++;
                            }

                            if (!LDH.IsObjectNull(customParentQuery3) && !LDH.IsValueEmpty(customParentQuery3)) {
                                customParentQuery3 = LDH.SetDateParametersForCommandLink(parentRowData, keys, customParentQuery3, timezoneName);
                                customParentQuery3 = LDH.ReplaceAll(customParentQuery3, "{Input_UserGroupId}", organizationId);
                                customParentQuery3 = LDH.FilterMacro(customParentQuery3);
                                for (let i2 = 0; i2 < keys.length; i2++) {
                                    customParentQuery3 = LDH.ReplaceAll(customParentQuery3, "{" + keys[i2] + "}", parentRowData[keys[i2]]);
                                }
                                if (!LDH.IsValueEmpty(customParentQuery3)) ajaxAdditionalCount++;
                            }

                            if (!LDH.IsObjectNull(reportDef.reportCustomDataSources[v].queryShaping) &&
                                !LDH.IsValueEmpty(reportDef.reportCustomDataSources[v].queryShaping)) {
                                try {
                                    eval(reportDef.reportCustomDataSources[v].queryShaping);
                                } catch (ex) {
                                    console.log("Failed to execute queryShaping.");
                                }
                            }

                            if (LDH.IsValueEmpty(customQuery) === false) {
                                LeopardQueryHelper.ExecuteQueriesForReport(apiUrl, customParentQuery1, customParentQuery2, customParentQuery3,
                                    customQuery, postDataQuery, ajaxRequestObj, function (data, queryType, ajaxTotalCount) {
                                        if (!LDH.IsObjectNull(data) && typeof data.finalData !== "undefined") {
                                            data = data.finalData;
                                        }
                                        resultData[dsName] = data;
                                        ajaxCount += ajaxTotalCount;
                                    }, function () {
                                        resultData[dsName] = null;
                                        ajaxCount++;
                                    });
                            } else {
                                resultData[dsName] = [];
                                if (!LDH.IsObjectNull(postDataQuery) &&
                                    !LDH.IsValueEmpty(postDataQuery)) {
                                    let data = LRH.ExecuteClientScript([],
                                        postDataQuery, "data", true);
                                    resultData[dsName] = data;
                                }
                                ajaxCount++;
                            }
                        }
                    }

                    let interval = setInterval(function () {
                        let count = 0;
                        if (typeof reportDef.reportCustomDataSources !== "undefined") {
                            count = reportDef.reportCustomDataSources.length;
                            count += ajaxAdditionalCount;
                        }
                        if (ajaxCount >= count) {
                            if (interval !== null) clearInterval(interval);
                            Promise.all(ajaxRequestObj).then(() => {
                                that.exportReportToPDF(resultData, reportDef);
                            }).catch(() => {
                                $(".leopard-popupwindow-loading-progress").hide();
                            });
                        }
                    }, 100);
                }, function (data) {

                });

            }, function (error, sessionTimeout) {
                if (sessionTimeout !== undefined && sessionTimeout === true) {
                    LRH.ShowToast("Your session has timed out. Please login again.", "error", 5000);
                } else {
                    LRH.ShowToast("Failed to retrieve Data View details. " +
                        "Please check your network connection status and try again.", "error", 5000);
                }
            });
        }

        if (that.instanceInitialized === false) {
            that.instanceInitialized = true;
            LRH.AddDisposablePopupInstances(that);
        }
    };

    exportReportToPDF = (data, reportDefinition) => {
        let report = new window.Stimulsoft.Report.StiReport();
        let dataSet = new window.Stimulsoft.System.Data.DataSet("ReportDataSet");
        let dataSetTimeZone = new window.Stimulsoft.System.Data.DataSet("ReportTimeZone");
        let timezones = LeopardDropdownHelper.DropdownSelectionTimeZone;

        let defaultDS = [];
        if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data["_defaultDS"])) {
            defaultDS = data["_defaultDS"];
        }
        dataSet.readJson({data: defaultDS});
        dataSetTimeZone.readJson({timezones: timezones});

        report.dictionary.dataStore.clear();
        report.regData(dataSet.dataSetName, "", dataSet);
        report.regData(dataSetTimeZone.dataSetName, "", dataSetTimeZone);

        let customDataSetList = [];
        if (!LDH.IsObjectNull(reportDefinition.reportCustomDataSources)) {
            let sources = reportDefinition.reportCustomDataSources;
            for (let v = 0; v < sources.length; v++) {
                let dsName = sources[v].name;
                let customDS = new window.Stimulsoft.System.Data.DataSet(dsName);

                let customData = [];
                if (!LDH.IsObjectNull(data) && !LDH.IsObjectNull(data[dsName])) {
                    customData = data[dsName];
                }
                let jsonData = {[dsName.toLowerCase()]: customData};
                customDS.readJson(jsonData);
                report.regData(dsName, "", customDS);
                customDataSetList.push({name: dsName, dataset: customDS});
            }
        }

        if (!LDH.IsObjectNull(reportDefinition.reportLayout) &&
            !LDH.IsValueEmpty(reportDefinition.reportLayout)) {
            report.load(reportDefinition.reportLayout);

            let jsCode = reportDefinition.clientSideUILogicForReport;
            if (!LDH.IsObjectNull(jsCode) && !LDH.IsValueEmpty(jsCode)) {
                jsCode = LDH.FilterMacro(jsCode);
                LRH.ExecuteClientScript(report, jsCode, "report", false);
            }

            let varibles = report.dictionary.variables;
            if (!LDH.IsObjectNull(varibles) && varibles.list.length > 0) {
                let parentRowData = window.Global_PopupCustomColumnData.e.row.data;
                let keys = Object.keys(parentRowData);

                for (let v = 0; v < varibles.list.length; v++) {
                    let variable = varibles.list[v];

                    for (let v1 = 0; v1 < keys.length; v1++) {
                        if (variable.val === "{" + keys[v1] + "}") {
                            variable.val = parentRowData[keys[v1]];
                        }

                        let timezoneName = "Australia/Sydney";
                        let oldValue = variable.val;
                        variable.val = LDH.SetDateParametersForCommandLink(parentRowData, keys, variable.val, timezoneName);

                        if (oldValue !== variable.val && !LDH.IsValueEmpty(variable.val) &&
                            variable.val.toString().indexOf("T") > -1 &&
                            variable.val.toString().indexOf("-") > -1 &&
                            variable.val.toString().indexOf("Z") > -1) {
                            let dateData = new Date(variable.val);
                            variable.val = LDH.ConvertDateToDDMMYYYY(dateData, "/");
                        }
                    }
                }
            }

            let options = new window.Stimulsoft.Viewer.StiViewerOptions();
            options.appearance.interfaceType = window.Stimulsoft.Viewer.StiInterfaceType.Mouse;
            options.appearance.showTooltipsHelp = false;
            options.appearance.scrollbarsMode = false;
            options.toolbar.showDesignButton = false;
            options.toolbar.showAboutButton = false;
            options.toolbar.showOpenButton = false;
            options.toolbar.showParametersButton = false;

            options.exports.showExportToText = false;
            options.exports.showExportToPowerPoint = false;
            options.exports.showExportToOpenDocumentCalc = false;
            options.exports.showExportToOpenDocumentWriter = false;
            options.exports.showExportToJson = false;
            options.exports.showExportToCsv = false;
            options.exports.showExportToWord2007 = false;
            options.exports.showExportToDocument = false;

            this.uiObjectInstances["reportViewer"] = new window.Stimulsoft.Viewer.StiViewer(
                options, "LeopardReportViewer", false);

            this.uiObjectInstances["reportViewer"].report = report;
            this.uiObjectInstances["reportViewer"].renderHtml("reportViewerContent");

            setTimeout(function () {
                $(".leopard-popupwindow-loading-progress").hide();
            }, 3000);
        }
    };

    onDropZoneEnter(e) {
        if (e.dropZoneElement.id.indexOf("leopard-photo-dragdrop-zone") > -1) {
            $("#" + e.dropZoneElement.id).addClass("active");
        }
    }

    onDropZoneLeave(e) {
        if (e.dropZoneElement.id.indexOf("leopard-photo-dragdrop-zone") > -1) {
            $("#" + e.dropZoneElement.id).removeClass("active");
        }
    }

    onUploadStarted(e, guid, data) {
        let that = this;
        const {file} = e;
        let fileReader = new FileReader();
        fileReader.onload = () => {
            $("#leopard-photo-display-thumbnail-" + guid).removeClass("hidden");
            $("#leopard-photo-dragdrop-zone-text-" + guid).addClass("hidden");
            $("#leopard-photo-dragdrop-zone-" + guid).removeClass("active");
            $("#leopard-photo-display-thumbnail-" + guid).attr("src", "");
            $("#leopard-photo-display-thumbnail-" + guid).attr("src", fileReader.result);
            const fileReader2 = new FileReader();
            fileReader2.onload = () => {
                let tileViewItemsCloned = that.state.tileViewItems;
                for (let i = 0; i < tileViewItemsCloned.length; i++) {
                    if (tileViewItemsCloned[i].id === data.id) {
                        let options = {
                            maxSizeMB: 0.1,
                            maxWidthOrHeight: 600,
                            useWebWorker: true
                        }
                        imageCompression(file, options).then(function (compressedFile) {
                            let thumbnailReader = new FileReader();
                            thumbnailReader.onload = function () {
                                let thumbnailData = thumbnailReader.result;
                                tileViewItemsCloned[i].imageUrl = thumbnailData;
                                tileViewItemsCloned[i].originalImageUrl = fileReader.result;
                                tileViewItemsCloned[i].imageData = fileReader2.result;

                                let fileReader3 = new FileReader();
                                fileReader3.onload = () => {
                                    tileViewItemsCloned[i].thumbnailData = fileReader3.result;
                                    that.setState({tileViewItems: tileViewItemsCloned}, function () {
                                        that.updatePhotoCountOnButton(that);

                                        setTimeout(function () {
                                            $(".leopard-photogallery-enlarged-image").attr("src", fileReader.result);
                                            $(".leopard-photo-dragdrop-zone").removeClass("selected");
                                            $("#leopard-photo-dragdrop-zone-" + guid).addClass("selected");
                                        }, 100);
                                    });
                                };
                                fileReader3.readAsArrayBuffer(compressedFile);
                            };
                            thumbnailReader.readAsDataURL(compressedFile);
                        });
                    }
                }
            };
            fileReader2.readAsArrayBuffer(file);
        };
        fileReader.readAsDataURL(file);
    }

    removePhotoOnClick = (e, imageId, thisComp) => {
        let tileViewItemsCloned = thisComp.state.tileViewItems;
        for (let i = 0; i < tileViewItemsCloned.length; i++) {
            if (tileViewItemsCloned[i].id === imageId) {
                tileViewItemsCloned[i].imageUrl = thisComp.emptyImageUrl;
                tileViewItemsCloned[i].originalImageUrl = thisComp.emptyImageUrl;
                tileViewItemsCloned[i].imageData = "";
                tileViewItemsCloned[i].thumbnailData = "";
                thisComp.selectedPhotoId = null;
            }
        }
        thisComp.setState({tileViewItems: tileViewItemsCloned}, function () {
            setTimeout(function () {
                $(".leopard-photogallery-enlarged-image").attr("src", thisComp.emptyImageUrl);
                thisComp.updatePhotoCountOnButton(thisComp);
            }, 100);
        });
    }

    initializePhotoGalleryTileViewItem = (data) => {
        let commandLinkData = window.Global_PopupCustomColumnData.data;
        let allowUploadPhotos = !LDH.IsObjectNull(commandLinkData.allowUploadPhotos) && commandLinkData.allowUploadPhotos;

        let isSelected = "";
        if (data.id === this.state.tileViewItems[0].id && !allowUploadPhotos) {
            isSelected = "selected";
        }

        let hasUploadImage = allowUploadPhotos && data.imageUrl.indexOf("EmptyPhoto.jpg") === -1;
        if (allowUploadPhotos) {
            $(".leopard-popupwindow-loading-progress").hide();
            $(".leopard-photocallery-container-largephoto-text").hide();
            $(".leopard-photocallery-button-full-screen").hide();
            $(".leopard-photocallery-button-upload").show();
        }
        if (!hasUploadImage && allowUploadPhotos) isSelected = false;

        return (
            <React.Fragment>
                <div className={"leopard-thumbnail-image"} style={{width: "100%", height: "100%", padding: "5px"}}
                     onClick={() => this.tileViewItemImageOnClick({
                         originalImageUrl: data.originalImageUrl, id: data.id, data: data
                     }, this)}>
                    {
                        allowUploadPhotos ?
                            <React.Fragment>
                                <img className={"leopard-photo-display-thumbnail " + (hasUploadImage ? "" : "hidden")}
                                     id={"leopard-photo-display-thumbnail-" + data.id} src={data.imageUrl} alt={""}/>
                                <div id={"leopard-photo-dragdrop-zone-" + data.id}
                                     className={"leopard-photo-dragdrop-zone " + isSelected}>
                                    <div id={"leopard-photo-dragdrop-zone-text-" + data.id}
                                         className={"flex-box dx-theme-accent-as-border-color dropzone-active leopard-photo-dragdrop-zone-text " + (hasUploadImage ? "hidden" : "")}
                                         style={{margin: "auto", cursor: "default"}}>
                                        <div className="flex-box">
                                            <div style={{textAlign: "center"}}>
                                                Drag & drop a file, or click to browse for a file
                                            </div>
                                        </div>
                                    </div>

                                    <FileUploader
                                        dialogTrigger={"#leopard-photo-dragdrop-zone-" + data.id}
                                        dropZone={"#leopard-photo-dragdrop-zone-" + data.id}
                                        multiple={false}
                                        allowedFileExtensions={['.jpg', '.jpeg']}
                                        uploadMode="instantly"
                                        uploadUrl=""
                                        visible={false}
                                        onDropZoneEnter={this.onDropZoneEnter}
                                        onDropZoneLeave={this.onDropZoneLeave}
                                        onUploadStarted={(e) => this.onUploadStarted(e, data.id, data)}
                                    ></FileUploader>
                                </div>
                            </React.Fragment>
                            :
                            <div className={data.id + " leopard-tileviewitem-image " + isSelected}
                                 style={{backgroundImage: `url(${data.imageUrl})`}}/>
                    }
                </div>
            </React.Fragment>
        );
    };

    initializePhotoGalleryViewContainer() {
        return (
            <div style={{display: "flex", height: "560px"}}>
                <div className={"leopard-photocallery-container"}
                     style={{display: (this.showThumbnails ? "block" : "none")}}>
                    <TileView items={this.state.tileViewItems} width={210} height={"100%"}
                              baseItemHeight={140} direction={"vertical"} baseItemWidth={210}
                              itemMargin={0} showScrollbar={"never"}
                              ref={(e) => this.addUIObjectInstance({name: "tileview", instance: e})}
                              itemComponent={this.initializePhotoGalleryTileViewItem}>
                    </TileView>
                </div>
                <div style={{width: "100%", padding: "10px 10px 5px 5px"}}>
                    <img className={"leopard-photogallery-enlarged-image"}
                         onError={(e) => this.photoGalleryImageOnError(e)}
                         alt={"Attachments"}/>
                    <div className={"leopard-photocallery-container-largephoto-text"}>
                        Right click the photo to copy or download
                    </div>
                </div>
            </div>
        );
    }

    initializePhotoGalleryAccessPanel = () => {
        let allowUploadPhotos = false;

        if (!LDH.IsObjectNull(window.Global_PopupCustomColumnData) && !LDH.IsObjectNull(window.Global_PopupCustomColumnData.data)) {
            let commandLinkData = window.Global_PopupCustomColumnData.data;
            allowUploadPhotos = !LDH.IsObjectNull(commandLinkData.allowUploadPhotos) && commandLinkData.allowUploadPhotos
        }
        let showDelete = false;
        for (let i = 0; i < this.state.tileViewItems.length; i++) {
            let imageUrl = this.state.tileViewItems[i].imageUrl;
            if (this.selectedPhotoId === this.state.tileViewItems[i].id &&
                allowUploadPhotos && imageUrl.indexOf("EmptyPhoto.jpg") === -1) {
                showDelete = true;
            }
        }

        return (
            <div style={{padding: "10px 0px 10px 5px", clear: "both", height: "46px"}}>
                <span style={{padding: "0px 10px 0 0", float: "right"}}>
                    <Button className="leopard-standard-button leopard-photocallery-button-full-screen"
                            style={{minWidth: "100px"}} text={"Full Screen"}
                            ref={(e) => this.addUIObjectInstance({name: "photoGalleryButtonFullScreen", instance: e})}
                            onClick={(e) => this.photoGalleryFullScreenOnClick(e)}>
                    </Button>
                    {
                        allowUploadPhotos ?
                            <span className={"attachmentPopup_photoGalleryButtonDelete_" + this.props.dataViewId}
                                  style={{display: (showDelete ? "inline" : "none")}}>
                                <Button className="leopard-standard-button leopard-photocallery-button-upload"
                                        style={{minWidth: "100px", marginRight: "10px"}} text={"Delete"}
                                        ref={(e) => this.addUIObjectInstance({
                                            name: "photoGalleryButtonDelete",
                                            instance: e
                                        })}
                                        onClick={(e) => this.removePhotoOnClick(e, this.selectedPhotoId, this)}>
                                </Button>
                            </span> : null
                    }
                    {
                        allowUploadPhotos ?
                            <Button className="leopard-standard-button leopard-photocallery-button-upload"
                                    style={{minWidth: "100px"}} text={"Save"}
                                    ref={(e) => this.addUIObjectInstance({
                                        name: "photoGalleryButtonUpload",
                                        instance: e
                                    })}
                                    onClick={(e) => this.photoGallerySaveOnClick(e)}>
                            </Button> : null
                    }
                </span>
            </div>
        );
    };

    initializePDFViewContainer() {
        return (
            <div style={{display: "flex", height: "612px", width: "100%", overflowY: "auto"}}>
                <div className={"leopard-pdfviewer-container"} style={{width: "100%", padding: "10px"}}>
                    <div id={"reportViewerContent"}></div>
                </div>
            </div>
        );
    }

    tabItemTitleRender = (data) => {
        return (
            <React.Fragment>
                <span className={"tabpanel_header_" + data.tabId}>
                    {data.tabTitle}
                </span>
            </React.Fragment>
        );
    };

    tabItemContent = (data) => {
        if (data.e.tabId === 0) {
            return (
                <React.Fragment>
                    <div className={"leopard-popupwindow-loading-progress"}>
                        <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                    </div>
                    <div className={"leopard-photocallery-container-nophoto"}>
                        <div style={{textAlign: "center", height: "100%", marginTop: "25%", fontSize: "14px"}}>
                            Sorry, there are no photos attached to this item.
                        </div>
                    </div>
                    {this.initializePhotoGalleryViewContainer()}
                    {this.initializePhotoGalleryAccessPanel()}
                </React.Fragment>
            );
        } else if (data.e.tabId === 1) {
            return (
                <React.Fragment>
                    <div className={"leopard-popupwindow-loading-progress"}>
                        <i className="fas fa-spinner fa-pulse" style={{fontSize: "25px"}}></i>
                    </div>
                    {this.initializePDFViewContainer()}
                </React.Fragment>
            );
        }
        return null;
    };

    render() {
        if (this.disposingAllInstances) return null;
        return (
            <Popup className={'leopard-popup-window'} visible={this.state.popupVisible} dragEnabled={true}
                   hideOnOutsideClick={false} onHiding={this.popupOnHide} onShown={this.popupOnShown}
                   showTitle={true} title={this.props.popupTitle} width={this.props.popupWidth}
                   ref={this.props.popupPhotoGalleryInstance} height={this.props.popupHeight}
                   onShowing={this.popupOnShowing}>
                <TabPanel dataSource={LeopardDropdownHelper.DropdownSelectionAttachmentTabPanel}
                          animationEnabled={false} swipeEnabled={false} showNavButtons={true}
                          ref={(e) => this.addUIObjectInstance({name: "tabPanel", instance: e})}
                          itemTitleRender={this.tabItemTitleRender}
                          itemComponent={(e) => this.tabItemContent({e})}>
                </TabPanel>
            </Popup>
        )
    }
}

export default LeopardAttachmentPopup;
